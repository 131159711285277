<script setup lang="ts">
import { CfgButton } from '@cfgtech/ui'
import type { SbButtonStoryblok } from '~/types/storyblok'

const { blok } = defineProps<{ blok: SbButtonStoryblok }>()

const { trackEvent } = useDataLayer()

function handleClick() {
  if (blok.ga_event) {
    trackEvent(blok.ga_event)
  }
  if (blok.link) {
    navigateTo(blok.link, { external: true })
  }
  else {
    console.warn('No link provided')
  }
}
</script>

<template>
  <CfgButton
    class="h-12 rounded-full px-8 py-0.5 !font-semibold"
    :class="{
      '!text-white': blok.variant === 'primary',
      '!text-primary': blok.variant === 'secondary',
      '!border-primary': blok.variant === 'secondary',
      'max-md:w-full': blok.mobileFullWidth,
    }"
    :label="blok.label"
    small
    :variant="blok.variant === 'primary' ? 'orange' : 'orange-light'"
    @click="handleClick"
  />
</template>
