export function useDataLayer() {
  const { dataLayer } = useScriptGoogleTagManager()

  function trackEvent(event: string) {
    if (!event)
      return

    dataLayer.push({
      event,
    })
  }

  return {
    trackEvent,
  }
}
